import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUtensils, faCheckSquare } from "@fortawesome/free-solid-svg-icons";

import Layout from "../components/layout";

import Seo from "../components/seo"
// import Image from "../components/image";

const pageAbout = ({ data, location }) => (
   <Layout>
      <Seo
         pagetitle={`ZEROSTEPについて`}
         pagedesc={`ZEROSTEPは、熊本のバスケットボール情報をお送りするWEBマガジンです。`}
         pagepath={location.pathname}
         pageimg={data.about.childImageSharp.original.src}
         pageimgw={data.about.childImageSharp.original.width}
         pageimgh={data.about.childImageSharp.original.height}
      />
      <div className="eyecatch">
         <StaticImage src="../images/thumb.jpg"  alt="N.LEAGUE TOP" style={{width:"100%"}} />
      </div>

      <section className="food">
         <div className="container">
            <h2 className="bar">Food <span>Essence</span></h2>

            <div className="details">
               <div className="detail">
                  <StaticImage src="../images/fruit.jpg"  alt="N.LEAGUE TOP" style={{width:"100%"}} />
                  <h3>フルーツ</h3>
                  <p>FRUIT</p>
                  <p>甘くてすっぱくておいしい果実たち。<br/>旬のフルーツを満喫します。</p>
               </div>

               <div className="detail">
                  <StaticImage src="../images/grain.jpg"  alt="N.LEAGUE TOP" style={{width:"100%"}} />
                  <h3>穀物</h3>
                  <p>GRAIN</p>
                  <p>食事の基本となる穀物。<br/>毎日の活動のエネルギー源になります。</p>
               </div>

               <div className="detail">
                  <StaticImage src="../images/beverage.jpg"  alt="N.LEAGUE TOP" style={{width:"100%"}} />
                  <h3>飲み物</h3>
                  <p>BEVERAGE</p>
                  <p>リラックスするのに欠かせない飲み物。<br/>お気に入りの一杯はありますか？</p>
               </div>
            </div>
         </div>
      </section>

      <article className="content">
         <div className="container">
            <h1 className="bar">ESSENTIALSについて</h1>

            <aside className="info">
               <div className="subtitle">
                  <FontAwesomeIcon icon={faUtensils} />
                  ABOUT ESSENTIALS
               </div>
            </aside>

            <div className="postbody">
               <p>体に必要不可欠な食べ物についての情報を発信しているサイトです。「おいしい食材をおいしく食べる」をモットーにしています。特に力を入れているのが、フルーツ、穀物、飲み物の３つです。</p>

               <h2>
                  <FontAwesomeIcon icon={faCheckSquare} />
                  公開している記事
               </h2>

               <p>これらについて、次のような記事やレシピなどを書いて公開しています。</p>

               <ul>
                  <li>ヘルシーで美味しい料理の研究・レビュー・レシピ。</li>
                  <li>一人でも、家族や友人と一緒にでも楽しめる料理。</li>
                  <li>ユーザー間のオープンなコミュニケーションを基盤とした情報。</li>
               </ul>

               <h2>
                  <FontAwesomeIcon icon={faCheckSquare} />
                  よく聞かれること
               </h2>

               <p>よく聞かれることなのですが、私たちはスタートアップではありません。私たちはまだスタートしたばかりで、より多くの人々が食べやすい食べ物がもたらす違いを発見できるように、成長しサービスを改善する機会を待っています。</p>

               <p>気長にお付き合いいただければ幸いです。</p>
            </div>
         </div>
      </article>

   </Layout>
)
export default pageAbout;

export const query = graphql`{
  about: file(relativePath: {eq: "thumb.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
      original {
        src
        height
        width
      }
    }
  }
}
`